.not-found {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3em;
  .anticon-bug {
    padding-right: 1em;
  }
}
