@import '~antd/dist/antd.css';

.ant-layout {
  height: 100%;
  .ant-layout-header {
    position: fixed;
    z-index: 1;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    .logo {
      font-size: 3em;
      color: #f0f2f5;
    }
  }
  .ant-layout-content {
    width: 100%;
    overflow: auto;
    margin-bottom: 54px;
    margin-top: 64px;
    padding: 50px 50px 10px 50px;
    .site-layout-content {
      box-shadow: 0px 0px 5px 1px gray;
      background-color: white;
      padding: 24px;
      height: 100%;
      overflow: auto;
    }
  }

  .ant-layout-footer {
    position: fixed;
    bottom: 0;
    z-index: 1;
    width: 100%;
    padding: 10px;
    .anticon {
      padding: 10px;
    }
  }
}
