.mathMarkDownViewer {
  padding: 0 10px;

  p {
    margin: 0 0 10px 0;
    font-family: inherit;
    font-size: 14px;
  }

  h1 {
    margin: 0 10px 10px 0;
  }
  h2 {
    margin: 0 8px 8px 0;
  }
  h3 {
    margin: 0 6px 6px 0;
  }
  h4 {
    margin: 0 4px 4px 0;
  }
  h5 {
    margin: 0 2px 2px 0;
  }
  h6 {
    margin: 0;
  }

  table {
    border-collapse: collapse;
    margin: auto;

    tr:hover {
      background-color: rgba(0, 0, 0, 0.14) !important;
    }

    thead tr {
      background-color: rgba(0, 0, 0, 0.21);
    }

    tr td {
      padding: 2px 5px;
    }

    tr:nth-child(even) {
      background-color: rgba(0, 0, 0, 0.07);
    }
  }
}
